import axios from "axios";
// import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_BASE_URL;

class UserService {
  //  Login
  login(data) {
    return axios.post(API_URL + `/user/login`, data);
  }

  forgetPassword(data) {
    return axios.post(API_URL + `/user/forgetPassword`, data);
  }

  resetPassword(userId, tokenId, data) {
    return axios.put(
      API_URL + `/user/password-reset/${userId}/${tokenId}`,
      data
    );
  }
}
export default new UserService();
