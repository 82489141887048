import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import AdminLayout from "./layout/AdminLayout";
// import AgentLayout from "./layout/AgentLayout";
import RequireAuth from "./layout/RequireAuth";
import Login from "./pages/Login";
import UnauthorizedPage from "pages/UnauthorizedPage";
import "assets/styles/tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/loarder.css";
import ForgetPassword from "pages/ForgetPassword";
import PasswordReset from "pages/PasswordReset";
import PageNotFound from "pages/PageNotFound";

const AdminLayout = lazy(() => import("./layout/AdminLayout"));
const AgentLayout = lazy(() => import("./layout/AgentLayout"));

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<RequireAuth />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/agent/*" element={<AgentLayout />} />
        <Route path="/unauthorized-page" element={<UnauthorizedPage />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/password-reset/:uid/:token" element={<PasswordReset />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
