import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthService from "../services/auth.services";
import TokenService from "../services/token.service";
import Image from "@material-tailwind/react/Image";

import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";
import Picture from "../assets/img/logo.png";

export default function Login() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,

    //  Yup validation

    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Required"),
      password: Yup.string().max(50).required("Required"),
    }),

    // Submit data

    async onSubmit(values) {
      await AuthService.login(values)
        .then((res) => {
          if (res.data.data.userStatus === "Active") {
            localStorage.setItem("user", JSON.stringify(res.data.data));
            navigate("/");
          } else {
            navigate("/active");
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    },
  });

  const userStore = TokenService.getUser();

  if (userStore) {
    return navigate("/");
  }

  return (
    <div className="flex h-screen items-center justify-center bg-pack-train backdrop-blur-3xl">
      <div>
        <Card className="w-96 ">
          <div className="bg-gradient-to-tr from-sideBarBlackFrom to-sideBarBlackTo shadow-lg p-2 rounded-lg ">
            <Image src={Picture} />
          </div>
          <form className="form" onSubmit={formik.handleSubmit}>
            <CardBody className="mt-5">
              <div className="text-gray-600 font-medium text-2xl capitalize">
                Sign In
              </div>
            </CardBody>

            <CardBody className="mt-4">
              <div className="mb-8 px-4">
                <InputIcon
                  outline={true}
                  color="gray"
                  placeholder="Email"
                  name="email"
                  iconName="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={
                    (formik.touched.email && Boolean(formik.errors.email),
                    formik.touched.email && formik.errors.email)
                  }
                />
              </div>
              <div className="mb-4 px-4">
                <InputIcon
                  outline={true}
                  color="gray"
                  type="password"
                  placeholder="Password"
                  name="password"
                  iconName="lock"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={
                    (formik.touched.password && Boolean(formik.errors.password),
                    formik.touched.password && formik.errors.password)
                  }
                />
              </div>
            </CardBody>
            <CardFooter>
              <div className="flex justify-center">
                <Button
                  color="gray"
                  buttonType="filled"
                  size="lg"
                  rounded={false}
                  block={true}
                  iconOnly={false}
                  ripple="light"
                  type="submit"
                >
                  Sign In
                </Button>
              </div>

              <div className="flex justify-end p-4">
                <p className="forget-password text-sm font-medium text-[#508daa] text-gray-600 hover:text-gray-700">
                  <Link to="/forget-password">Forget Password ?</Link>
                </p>
              </div>
            </CardFooter>
          </form>
        </Card>
      </div>
    </div>
  );
}
