import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AuthService from "../services/auth.services";
import TokenService from "../services/token.service";

import Card from "@material-tailwind/react/Card";
import CardImage from "@material-tailwind/react/CardImage";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";
import Picture from "../assets/img/resetPassword.webp";

export default function PasswordReset() {
  const params = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    enableReinitialize: true,
    //  Yup validation
    validationSchema: Yup.object().shape({
      password: Yup.string().min(8).max(16).required("Required"),
    }),

    // Submit data
    async onSubmit(values) {
      if (params.uid && params.token) {
        const data = {
          password: values.password,
        };
        await AuthService.resetPassword(params.uid, params.token, data)
          .then((response) => {
            navigate("/login");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    },
  });

  const userStore = TokenService.getUser();

  if (userStore) {
    return navigate("/");
  }

  return (
    <>
      <div className="flex h-screen items-center justify-center bg-gradient-to-r from-sideBarBlackFrom to-sideBarBlackTo">
        <div>
          <Card className="">
            <CardImage className="w-96" src={Picture} alt="Card Image" />
            <form className="form" onSubmit={formik.handleSubmit}>
              <CardBody className="mt-10">
                <div className="mb-4 px-4">
                  <InputIcon
                    outline={true}
                    type="password"
                    color="gray"
                    placeholder="Password"
                    name="password"
                    iconName="lock"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={
                      (formik.touched.password &&
                        Boolean(formik.errors.password),
                      formik.touched.password && formik.errors.password)
                    }
                  />
                </div>
              </CardBody>
              <CardFooter>
                <div className="flex justify-center">
                  <Button
                    color="gray"
                    buttonType="filled"
                    size="lg"
                    rounded={false}
                    block={true}
                    iconOnly={false}
                    ripple="light"
                    type="submit"
                  >
                    Updated Password
                  </Button>
                </div>
              </CardFooter>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
}
