import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import AuthService from "../services/auth.services";
import TokenService from "../services/token.service";
import { useNavigate, Link } from "react-router-dom";

import Card from "@material-tailwind/react/Card";
import CardImage from "@material-tailwind/react/CardImage";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";
import Picture from "../assets/img/forgetpassword.webp";

export default function ForgetPassword() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,

    //  Yup validation

    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Required"),
    }),

    // Submit data

    async onSubmit(values) {
      const data = {
        email: values.email,
      };

      await AuthService.forgetPassword(data)
        .then((response) => {
          toast.success("Please check your email");
          navigate("/");
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    },
  });

  const userStore = TokenService.getUser();

  if (userStore) {
    return navigate("/");
  }

  return (
    <>
      <div>
        <div className="flex h-screen items-center justify-center bg-gradient-to-tr from-sideBarBlackFrom to-sideBarBlackTo">
          <div className="w-full md:w-2/6">
            <Card>
              <CardImage src={Picture} alt="Card Image" />
              <form className="form" onSubmit={formik.handleSubmit}>
                <CardBody className="mt-5">
                  <div className="text-gray-600 font-medium text-2xl capitalize">
                    Forget Password
                  </div>
                </CardBody>

                <CardBody className="mt-5">
                  <div className="mb-8 px-4">
                    <InputIcon
                      outline={true}
                      color="gray"
                      placeholder="Email"
                      name="email"
                      iconName="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={
                        (formik.touched.email && Boolean(formik.errors.email),
                        formik.touched.email && formik.errors.email)
                      }
                    />
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="flex justify-center">
                    <Button
                      color="gray"
                      buttonType="filled"
                      size="lg"
                      rounded={false}
                      block={true}
                      iconOnly={false}
                      ripple="light"
                      type="submit"
                    >
                      Send
                    </Button>
                  </div>

                  <div className="flex justify-end p-4">
                    <p className="text-sm font-medium text-gray-600 hover:text-gray-700">
                      <Link to="/">Back to sign ?</Link>
                    </p>
                  </div>
                </CardFooter>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
